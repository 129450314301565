#footer {
  background-color: #2c3e50;
  height: auto;
  width: 100%;
  color: white;
  display: grid;
  justify-content: center;
  align-items: center;
  padding: 6vh 2vw 2vh 2vw;
}

#webdev {
  width: 150px;
  height: auto;
  margin: auto;
  margin-left: 10px;
}
