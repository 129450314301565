.kalendertabel {
  width: 90%;
  margin: auto;
  margin-bottom: 50px;
  text-align: left;
}

.kalender-weekpicker {
  display: grid;
  grid-template-columns: 50px auto 50px;
  grid-template-rows: 1fr;
  margin: auto;
  margin-bottom: 20px;
  width: 50%;
  min-width: 300px;
  text-align: center;
  justify-content: space-around;
  align-items: center;
}

.kalender-weekpicker i {
  font-size: 40px;
}

.kalender-weekpicker i:hover {
  color: #f1c40f
}

.kalender-weekpicker p {
  font-size: 22px;
  line-height: 40px;
}

.kalender-weekpicker .btn {
  grid-column-start: 0;
  grid-column-end: 3;
  margin: auto;
  width: 200px;
}

.coronakalender {
  height: 70px;
  display: table-cell;
  align-items: center;
  justify-content: center;
  vertical-align: middle;
}

@media only screen and (max-width: 550px) {
  .kalendertabel {
    width: 100%;
  }

  .kalender-weekpicker {
    min-width: 100%;
  }
}
