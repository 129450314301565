.activiteitpagina {
  color: #2c3e50;
  min-height: 60vh;
}

#googleformlink{
  background-color: #008CBA;
  border: none;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  border-radius: 8px;
  font-size: 22px;
}

#googleformlink:hover {
  box-shadow: 0 12px 16px 0 rgba(0,0,0,0.24), 0 17px 50px 0 rgba(0,0,0,0.19);
}

.bg-activiteit {
  background-image: ;
  background-size: cover;
  background-position: center;
  background-color: #58aff6;
  top: 0;
  height: 40vh;
  max-height: 40vw;
  width: 100%;
  z-index: -2;
  overflow-x: hidden;
  background-repeat: no-repeat;
}

.activiteit-text {
  min-width: 40vw;
  margin: auto;
  margin-top: 0px;
  margin-bottom: 10vh;
  text-align: center;
}

.activiteit-inputtekst {
  resize: none;
  width: 80%;
  height: auto;
  border: none;
  margin: auto;
  margin-top: 50px;
  margin-bottom: 50px;
  text-align: center;
  white-space: pre-line;
  font-size: 19px;
}

.activiteit-titel {
  font-size: 4rem;
  font-family: 'Permanent Marker', sans-serif;
  color: #2c3e50;
  margin: 0vh auto 25px;
  width: 80%;
}

#googlebutton {
  display: block;
}

.inschrijfknop {
  background-color:#3a6dc4;
  border-radius:28px;
  border:1px solid #4e6096;
  display:inline-block;
  cursor:pointer;
  color:#ffffff;
  font-family:Arial;
  font-size:2rem;
  padding:16px 31px;
  text-decoration:none;
  text-shadow:0px 1px 0px #283966;
  margin-bottom: 5vh;
}

.inschrijfknop:hover {
  background-color:#476e9e;
}

.activiteitaffiche {
  height: auto;
  width: 500px;
  margin: auto;
  margin-bottom: 5vh;
}

.activiteitenfotos {
  display: flex;
  justify-content: space-around;
  width: 90%;
  margin: auto;
  flex-wrap: wrap;
}

.activiteitfoto {
  width: 500px;
  margin-bottom: 50px;
}

@media only screen and (max-width: 550px) {

.activiteit-titel {
  font-size: 13vw;
  line-height: 12vw;
  width: 100%;
  margin: 0;
  margin-bottom: 2vh;
  text-align: center;
}

.activiteit-datum {
  font-size: 7vw;
  width: 100%;
  margin: 0;
  margin-bottom: 2vh;
  text-align: center;
}

.activiteit-text {
  max-width: 90vw;
  width: 100%;
  margin: auto;
}

.activiteitaffiche {
  width: 80vw;
}

.activiteitfoto {
  width: 80vw;
}

.bg-activiteit {
  height: 30vh;
  max-height: 40vh;
}

.activiteit-inputtekst {
  width: 90%;
}

#googleformlink{
  margin-bottom: 5vh;
}
}
