.imageSponsor {
  margin: 25px;
  margin-bottom: 50px;
  height: auto;
  width: 90%;
  max-height: 400px;
  max-width: 400px;
}

.imageSponsor:hover {
  cursor: pointer;
}

.sponsorslink {
  text-decoration: underline;
  font-weight: bolder;
}

.sponsorslink:hover {
  cursor: pointer;
  color: blue
}
