.Nieuwsoverzicht {
  width: 90%;
  margin: auto;
  margin-bottom: 70px;
  justify-content: center;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr;
  grid-column-gap: 30px;
}

.nieuws-card {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 200px 1fr;
  text-align: left;
  border: 1px solid rgba(0, 0, 0, .1);
  padding-bottom: 20px;
}

.nieuws-card:hover {
  cursor: pointer;
  background-color: #ecf0f1;
}

.nieuws-card-img {
  width: 100%;
  height: 200px;
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: ;
  margin-bottom: 25px;
}

.nieuws-card-datum {
  margin-top: 15px;
  margin-bottom: 8px;
  font-size: 16px;
  color: #2980b9;
}

.nieuws-card-datum i {
  margin-right: 5px;
}

.nieuws-card h4 {
  margin-bottom: 10px;
  padding: 0 15px;
  font-size: 1.8rem;
}

.nieuws-card p {
  height: auto;
  padding: 0 15px;
}

.nieuws-card a {
  margin: auto;
}

.nieuws-card button {
  margin: auto;
  background-color: #2c3e50;
  color: #ecf0f1;
  border: none;
}

.popup-content {
  padding: 20px 30px !important;
  border-radius: 20px;
}

.popupNieuws .btn {
  margin-right: 15px;
  background-color: #2c3e50;
  color: #ecf0f1;
  border: none;
}


@media only screen and (max-width: 800px) {

  .Nieuwsoverzicht {
    width: 90vw;
    margin: 25px auto;
    justify-content: center;
    display: block;
  }

  .nieuws-card {
    width: 100%;
    max-width: 400px;
    margin: auto;
    margin-bottom: 50px;
    grid-row-gap: 10px;
  }

  .popup-content {
    width: 90vw !important;
    height: 80vh;
    top: 35px;
    overflow: auto;
    z-index: 10000001;
  }

  .popupNieuws {
    width: 70vw;
    height: auto;

  }
}
