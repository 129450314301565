.backgroundBestuur {
  background: url("./bestuur.jpeg");
  background-size: cover;
  background-position: center;
  background-color: #58aff6;
  top: 0;
  height: 40vh;
  max-height: 40vw;
  width: 100%;
  z-index: -2;
  overflow-x: hidden;
  background-repeat: no-repeat;
  margin: auto;
}

.bestuurLijst {
  display: flex;
  width: 90%;
  height: auto;
  margin: 0px auto 50px;
  flex-wrap: wrap;
  justify-content: space-around;
}

.bestuurslidDisplay {
  display: grid;
  grid-template-columns: 150px 1fr;
  grid-template-rows: 1fr;
  grid-column-gap: 25px;
  width: 450px;
  height: auto;
  background-color: white;
  margin: 45px 25px;
  align-items: center;
}

.bestuurFoto {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  background-position: center;
  background-image: ;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: #2c3e50;
}

.bestuurslidText {
  padding: 10px 0px;
  text-align: left;
}

.bestuurslidText-top {
  border-bottom: 2px solid #2980b9;
  margin-bottom: 10px;
}

.bestuurslidText-top h5 {
  font-style: italic;
}

.bestuurslidText i {
  margin-right: 8px;
  color: #2980b9;
}

@media only screen and (max-width: 1115px) {
  .bestuurLijst {
    width: 95%;
  }

  .bestuurslidDisplay {
    width: 350px;
  }
}

@media only screen and (max-width: 600px) {

  .backgroundBestuur {
    height: 40vh;
    max-height: 40vh;
  }

  .bestuurslidDisplay {
    width: 80vw;
    display: block;
    text-align: center;
    margin: 25px;
  }

  .bestuurFoto {
    margin: auto;
  }

  .bestuurslidText {
    text-align: center;
  }

}
