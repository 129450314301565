.overzichtSporthal {
  margin-top: 5vh;
  margin-bottom: 5vh;
  height: auto;
}

.sporthalFoto {
  width: 30vw;
  height: auto;
  min-width: 400px;
  margin: 2vw;
  max-height: 100%;
  border-radius: 15px;
}

.backgroundSporthal {
  background: url("./sporthal.JPG");
  background-size: cover;
  background-color: #58aff6;
  background-position: 0 -50px;
  height: 40vh;
  max-height: 40vw;
  width: 100%;
  z-index: -2;
  overflow-x: hidden;
  background-repeat: no-repeat;
}

.sporthaladres {
  display: grid;
  grid-template-columns: 1fr 1fr;
  text-align: left;
  width: 50%;
  margin: auto;
}

.icons {
  width: 100%;
  height: auto;
  margin: auto;
  display: flex;
  justify-content: space-around;
}

@media only screen and (max-width: 550px) {

  .backgroundSporthal {
    max-height: 40vh;
    background-position: center;
  }

  .sporthaladres {
    display: block;
    text-align: center;
    width: 90%;
  }

  .sporthalFoto {
    width: 90%;
    min-width: 50px;
  }

}
