.ploeg-section {
  min-height: 60vh;
  padding-bottom: 5vh;
  color: #2c3e50;
  margin: 0;
  width: 100%;
  max-width: 100vw;
}

.ploegfoto {
  width: 95%;
  height: auto;
  margin: auto;
}

.ploegfoto:hover {
  cursor: pointer;
}
#ploegfoto-display {
  width: 100vw;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 30000000;
}

#ploegfoto-display:hover {
  cursor: pointer
}

#ploegfoto-display-img {
  margin: auto;
  width: 90%;
  height: auto;
  z-index: 30010;
  transform: translate(-50%, -50%);
  position: absolute;
  left: 50%;
  top: 50%;
}

#ploegfoto-display-bg {
  width: 100%;
  height: 100%;
  background-color: black;
  opacity: 0.7;
  z-index: 3000;
}

.ploegsection {
  margin: 5vh auto;
  width: 80%;
  padding: 0 25px;
  display: grid;
  grid-template-columns: 50% 1fr;
  grid-template-rows: 1fr;
  grid-column-gap: 50px;
  justify-content: center;
  text-align: left;
}

.ploegsection-sub {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 1fr;
}

.ploeg-titel {
  font-size: 5rem;
  line-height: 5rem;
  height: auto;
  font-family: 'Permanent Marker', sans-serif;
  text-align: center;
  color: #2c3e50;
  margin: 0vh auto 5vh auto;
  padding-bottom: 5px;
  width: 90%;
}

.backgroundPloeg {
  background-image: url("./jumbotron.jpeg");
  background-size: cover;
  background-color: #58aff6;
  top: 0;
  height: 40vh;
  max-height: 40vw;
  width: 100%;
  z-index: -2;
  overflow-x: hidden;
  background-repeat: no-repeat;
}

.ploegkalender {
  width: 80%;
  margin: auto;
}

.ploeg-section #section2 {
  width: 80%;
  height: auto;
  margin: auto;
  text-align: left;
}

.ploeg-section #section2 .kalendertabel{
  margin: auto;
}

.ploeg-section #section2 .table{
  margin-top: 5px;
}

@media only screen and (max-width: 900px) {
  .ploeg-titel {
    font-size: 10vw;
    line-height: 10vw;
  }

  .ploegsection {
    grid-template-columns: 1fr;
    justify-content: center;
    width: 100%;
  }

  .ploegsection-sub {
    grid-template-rows: 1fr;
  }

  .ploegsection h3 {
    margin-top: 5vh;
  }

  .ploegfoto {
    width: 100%;
  }

  .ploeg-section #section2 {
    width: 90% !important;
    margin: auto;
  }

}


@media only screen and (max-width: 550px) {

.backgroundPloeg {
  max-height: 40vh;
  background-position: right;
}
.ploeg-titel {
  font-size: 10vw;
  line-height: 10vw;
  width: 80%;
  margin: auto;
  margin-bottom: 2vh;
  text-align: center;
}

.ploegkalender {
  width: 100%;
  font-size: 3vw;
}



}
