#jumbotron {
  height: 70vh;
  background-image: url(./jumbotron.jpeg);
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 0;
  margin: 0;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  justify-content: center;
  align-items: center;
  color: white;
  position: relative;
}

#jumbotron-overlay {
  width: 100%;
  height: 100%;
  background-color: black;
  opacity: 0.6;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 0;
}

#jumbotron-text {
  z-index: 100;
  width: 90%;
  margin:  auto;
}

#jumbotron-text h1 {
  font-size: 2.5em;
}

#jumbotron-text h5 {
  font-style: italic;
}

.white-bg {
  position:relative;
  top: -35px;
  background-color: white;
  width: 100%;
  height: 9vh;
  transform: skewY(2deg);
  z-index: 0;
}

.section {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  width: 95%;
  margin: auto;
}

.titel-section {
  font-family: 'Permanent Marker', sans-serif;
  font-size: 4rem;
  color: #2c3e50;
  background-color: white;
  text-align: center;
  height: auto;
}

.onderlijn {
  border-bottom: 8px solid #f1c40f;
  margin: auto;
  margin-bottom: 40px;
  width: 200px;
  border-radius: 20px;
}

#section1 {
  background-color: white;
}

@media only screen and (max-width: 770px) {
  .titel-section {
    font-size: 4rem;
    width: 80%;
    text-align: center;
    margin: 0 auto 10px auto;
    padding: 0;
  }

  .onderlijn {
    border-bottom: 8px solid #f1c40f;
    margin: auto;
    margin-bottom: 30px;
    width: 200px;
    border-radius: 20px;
  }
}

@media only screen and (max-width: 550px) {

#jumbotron {
  height: 520px;
  background-position: right;
}

.white-bg {
  height: 50px;
  top: -10px;
}

.section {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  padding: 10px 25px;
  margin: auto;
  width: 95vw !important;
}

.titel-section {
  font-size: 2.5rem;
  text-align: center;
  padding: 0;
}

.onderlijn {
  border-bottom: 5px solid #f1c40f;
}



#section1 {
  margin: auto;
  padding-top: 0;
}


}
