#header {
  background-color: white;
  height: 80px;
  border-bottom-width: 1px;
  border-bottom-color: rgba(52,73,94, 0.3);
  border-bottom-style: solid;
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  z-index: 1000000;
  display: grid;
  grid-template-columns: 10% 1fr;
  grid-template-rows: 1fr;
  justify-content: space-between;
  align-self: center;
  font-size: 1.05em;
}

#header-flex{
  display: flex;
  justify-content: space-between;
}

#logo {
  width: 120px;
  height: auto;
  margin-right: 4vw;
  margin-left: 20px;
}

#logo:hover {
  cursor: pointer;
}

#user {
  position: absolute;
  top: 2px;
  right: 1.5vw;
  font-family: 'Montserrat', sans-serif;
}


.links {
  position: absolute;
  left: 1.5vw;
  top: 1vh;
}

.links ul {
  display: inline-flex;
  list-style: none;
}

.links ul li {
  margin-right: 1vw;
  font-family: 'Montserrat', sans-serif;
  font-size: 20px;
  text-transform: uppercase;
}

.links ul li:hover {
  color: #2980b9;
  text-decoration: underline;
  cursor: pointer;
}

.weblink {
  color: black;
  text-decoration: none;
}

.navbar-light .navbar-nav .nav-link {
  color: #2c3e50 !important;
}

.nav-link:hover {
  font-weight: bold;
}

.dropdown-item:hover {
  font-weight: bold;
}

@media only screen and (max-width: 990px) {
  #header {
    height: auto;
    min-height: 80px;
    display: flex;
  }

  .dropdown-menu a {
    text-align: center;
  }

  .weblink {
    text-align: center;
  }
}
