.backgroundLid {
  background: url("./inschrijven.jpeg");
  background-size: cover;
  background-position: center;
  background-color: #58aff6;
  top: 0;
  height: 40vh;
  max-height: 40vw;
  width: 100%;
  z-index: -2;
  overflow-x: hidden;
  background-repeat: no-repeat;
}

.infoTabel {
  width: 50vw;
  margin: auto;
}

.subtitel {
  font-size: 4rem;
  line-height: 4rem;
  font-family: 'Permanent Marker', sans-serif;
  margin-top: 5vh;
  margin-bottom: 3vh;
}

@media only screen and (max-width: 550px) {

.backgroundLid{
  height: 40vh;
  max-height: 40vh;
}
.subtitel {
  font-size: 10vw;
  line-height: 10vw;
}

.infoTabel {
  width: 100%;
}

}
