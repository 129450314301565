.backgroundSponsors {
  background: url("./sponsors.jpg");
  background-size: cover;
  background-position: center;
  background-color: #58aff6;
  top: 0;
  height: 50vh;
  max-height: 40vw;
  width: 100%;
  z-index: -2;
  overflow-x: hidden;
  background-repeat: no-repeat;
}

.sponsoroverzicht {
  color: #2c3e50;
}

.sponsor {
  background-image: "";
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  width: 400px;
  height: auto;
  margin: 3vh 5vw;
}

@media only screen and (max-width: 450px) {

.sponsor {
  width: 80%;
}

.backgroundSponsors{
  width: 100vw;
  max-height: auto;
}


}
