.backgroundContact {
  background: url("./contact.jpeg");
  background-size: cover;
  background-color: #58aff6;
  top: 0;
  height: 40vh;
  max-height: 40vw;
  width: 100%;
  z-index: -2;
  overflow-x: hidden;
  background-repeat: no-repeat;
}

@media only screen and (max-width: 550px) {

.backgroundContact {
  height: 40vh;
  max-height: 40vh;
}

}
