#root {
 background-color: #34495e;
}

.App {
  max-width: 1980px;
  text-align: center;
  color: #2c3e50;
  font-family: 'Roboto', sans-serif;
  margin: 0 auto;
  padding: 0;
  background-color: white;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 80px 1fr; /*hoogte header*/
  scroll-behavior: smooth;
}

p {
  font-size: 19px;
}

h3 {
  font-size: 32px !important;
}

#centraal {
  margin-left: auto;
  margin-right: auto;
}

#verificatie {
  width: 100vw;
  height: 80vh;
  display: grid;
  justify-content: center;
  align-items: center;
}
